<i18n lang="yaml">
ja:
  title: 友達にシェアしよう
  clipboard: コピーしました
en:
  title: Share with your friends
  clipboard: Copied
</i18n>

<template>
  <HaBaseDialog v-if="isOpen" class="ho-share-link-dialog" @close="closeDialog">
    <div class="title-container">
      <HoTitle class="title">{{ i18n.t('title') }}</HoTitle>
    </div>
    <div class="share-url-container">
      <span class="share-url">{{ shareUrl }}</span>
      <div class="icon-copy-container" @click="onCopyShareUrl">
        <IconCopy class="icon-copy" />
      </div>
    </div>
    <div class="icon-container">
      <HaLink
        :to="`/mypage/message/?shareUrl=${shareUrl}`"
        class="icon-message-container"
      >
        <IconMessage class="icon-message" />
      </HaLink>
      <HmSocialShareLink
        name="twitter"
        :share-url="shareUrl"
        :twitter-hashtags="['MyVket']"
        :text="customText || ''"
      >
        <HaBaseImage
          src="/images/sns/twitter.png"
          alt="twitter"
          :width="isSp ? 50 : 62"
          :height="isSp ? 50 : 62"
        />
      </HmSocialShareLink>
      <HmSocialShareLink name="line" :share-url="shareUrl">
        <HaBaseImage
          src="/images/sns/line.png"
          alt="line"
          :width="isSp ? 50 : 62"
          :height="isSp ? 50 : 62"
        />
      </HmSocialShareLink>
      <HmSocialShareLink name="facebook" :share-url="shareUrl">
        <HaBaseImage
          src="/images/sns/facebook.png"
          alt="facebook"
          :width="isSp ? 50 : 62"
          :height="isSp ? 50 : 62"
        />
      </HmSocialShareLink>
    </div>
  </HaBaseDialog>
</template>

<script setup lang="ts">
// assets
import IconCopy from '@/assets/icons/icon_copy.svg'
import IconMessage from '@/assets/icons/icon-message.svg'

type Props = {
  isOpen: boolean
  message: string
  closeMessage?: string
  confirmMessage?: string
  customShareUrl?: string
  customText?: string
}

type Emits = {
  (emit: 'close'): void
}

const props = defineProps<Props>()

const emits = defineEmits<Emits>()

const i18n = useI18n()

const router = useRouter()

const isSp = breakpoints.smaller('sp')

const config = useRuntimeConfig()

const toast = inject(toastInjectionKey)!
const { addToast } = toast

const shareUrl = computed(() => {
  return props?.customShareUrl
    ? props.customShareUrl
    : `${config.public.NUXT_ENV_URL}${router.currentRoute.value.fullPath}`
})

const closeDialog = () => {
  emits('close')
}

const onCopyShareUrl = () => {
  navigator.clipboard.writeText(shareUrl.value)
  addToast(i18n.t('clipboard'), 'success')
}
</script>

<script lang="ts">
export default defineComponent({
  name: 'HoConfirmDialog',
})
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;
@use 'sass:color';

.ho-share-link-dialog {
  z-index: 203;

  :deep(> .dialog-window[class]) {
    border-radius: 5px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 20%);

    > .content {
      margin: 0 auto;
      padding: 0;
      width: 100%;
    }

    @include m.sp() {
      // note: %指定に対してfit-contentが効かないので一番上の要素に横幅指定する
      width: 90%;
    }
  }

  .title-container {
    background: v.$gray;
    border-radius: 5px;
    color: v.$text-body;
    font-size: 16px;
    font-weight: 900;
    margin-bottom: v.space(4);
    padding: v.space(8) v.space(8) v.space(12) v.space(8);
    text-align: center;
    white-space: pre-wrap;

    @include m.sp() {
      padding: v.space(4) v.space(4) v.space(8);
    }

    .title {
      font-size: 20px;

      @include m.sp() {
        font-size: 16px;
      }
    }
  }

  .share-url-container {
    align-items: center;
    background: v.$white;
    border-radius: 50px;
    box-shadow: v.$box-shadow;
    display: flex;
    height: 40px;
    height: 50px;
    justify-content: space-between;
    margin: -6% v.space(4) 0;
    min-width: 676px;
    padding: 0 v.space(8) 0 v.space(8);

    @include m.sp() {
      margin: -10% v.space(4) 0;
      min-width: 311px;
      padding: 0 v.space(2) 0 v.space(4);
    }

    .share-url {
      color: v.$gray-3;

      @include m.sp() {
        font-size: 14px;
      }
    }
  }

  .icon-copy-container {
    align-items: center;
    background: v.$gray;
    border-radius: 30px;
    cursor: pointer;
    display: flex;
    height: 20px;
    justify-content: center;
    width: 20px;

    .icon-copy {
      height: 12px;
      width: 12px;

      :deep(path) {
        fill: v.$gray-5;
      }
    }
  }

  .icon-container {
    display: flex;
    gap: v.space(8);
    justify-content: center;
    padding: v.space(4) v.space(8) v.space(8);

    @include m.sp() {
      padding: v.space(4);
    }
  }

  .icon-message-container {
    align-items: center;
    background: v.$orange;
    border-radius: 64px;
    display: flex;
    height: 62px;
    justify-content: center;
    padding: v.space(4);
    width: 62px;

    > .icon-message {
      fill: v.$white;
      height: 28px;
      width: 28px;
    }

    @include m.sp() {
      height: 50px;
      padding: 0;
      width: 50px;
    }
  }
}
</style>
